import React, { useEffect } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Route, Routes, Link, useLocation  } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import logo from "../../Assets/logo.png"
import CampamentoXr from '../campamentoXr/CampamentoXr'
import LaboratorioXr from '../laboratorioXr/LaboratorioXr'
import Noticias from '../noticias/Noticias'
//import HallFama from '../hallFama/HallFama'
import Home from '../home/Home'
import "./NavbarVivi.scss"
import GuiaCampamentoXr from '../guiaCampamentoXr/GuiaCampamentoXr';

function NavbarVivi() {

  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return (
    <div className='w-100'>
      <Navbar style={{backgroundColor: "#4B1386"}} variant="primary" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand href="/#" rel="noopener noreferrer">
            <img src={logo} alt="Logo Uniandes" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
            <Nav className="justify-content-end">
              <Nav.Item>
                <Link to="/" className="nav-link">Inicio</Link>              
              </Nav.Item>
              <Nav.Item>
                <HashLink smooth to="/#description" className='nav-link'>Quienes somos</HashLink>
              </Nav.Item>
              <Nav.Item>
                <NavDropdown title="Líneas de acción" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#/campamento-xr">Campamento XR</NavDropdown.Item>
                  <NavDropdown.Item href="#/laboratorio-xr">Laboratorio XR</NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>
              <Nav.Item>
                <HashLink smooth to="/#events" className='nav-link'>Anuncios</HashLink>
              </Nav.Item>
              <Nav.Item>
                <HashLink smooth to="/#questions" className='nav-link'>Preguntas frecuentes</HashLink>
              </Nav.Item>
              <Nav.Item>
                <HashLink smooth to="/#contact" className='nav-link'>Contáctanos</HashLink>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/campamento-xr" element={<CampamentoXr />} />
        <Route path="/laboratorio-xr" element={<LaboratorioXr />} />
        <Route path="/noticia/:id" element={<Noticias />} />
        <Route path="/guia-campamento-xr" element={<GuiaCampamentoXr />} />
      </Routes>
    </div>
  )
}

export default NavbarVivi   