import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { FiMail } from "react-icons/fi";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedinIn, FaTiktok } from "react-icons/fa";
import "./ContactUs.scss"
function ContactUs() {
  return (
    <div className='d-flex justify-content-center contactUs'>
      <Card style={{width:"100%"}}>
        <Card.Body>
          <Row>
            <Col >
              <div className='icons'>
                <Row className='d-flex' style={{justifyContent: "flex-end"}}>
                  <Col style={{textAlign: "right"}}>
                  <a href="https://www.facebook.com/profile.php?id=100086579619980&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><FaFacebook size={25} /></a>
                  </Col>
                  <Col>
                  <a href="https://instagram.com/viverovirtual_uniandes?igshid=Yzg5MTU1MDY=" target="_blank" rel="noopener noreferrer"><FaInstagram size={25}/> </a>
                  </Col>
                </Row>
              </div>
             <p>@VIVEROVIRTUAL_UNIANDES</p> 
            </Col>
            
            <Col style={{borderRightStyle: "inset", borderLeftStyle: "outset"}}>
              <div className='d-flex justify-content-center icons'>
              <a href="mailto: viverovirtual@uniandes.edu.co"><FiMail size={25} /> </a>
              </div>
              <p><a href="mailto: viverovirtual@uniandes.edu.co" className='email'>viverovirtual@uniandes.edu.co</a></p>  
            </Col>

            <Col>
              <div>
                <Row className="text-center">
                  <Col className='icons'>
                  <a href="https://www.facebook.com/DISCUniandes" target="_blank" rel="noopener noreferrer"><FaFacebook size={25}/> </a>
                  </Col>
                  <Col>
                  <a href="https://www.instagram.com/discuniandes/" target="_blank" rel="noopener noreferrer"><FaInstagram size={25}/> </a>
                  </Col>
                  <Col>
                  <a href="https://twitter.com/discuniandes" target="_blank" rel="noopener noreferrer"><FaTwitter size={25} /> </a>
                  </Col>
                  <Col>
                  <a href="https://www.tiktok.com/@discuniandes?_t=8aQEZBS2bxN&_r=1" target="_blank" rel="noopener noreferrer"><FaTiktok size={25} /> </a>
                  </Col>
                  <Col>
                  <a href="https://www.linkedin.com/showcase/departamento-de-ingenier%C3%ADa-de-sistemas-y-computaci%C3%B3n/?originalSubdomain=co" target="_blank" rel="noopener noreferrer"><FaLinkedinIn size={25}/></a>
                  </Col>
                </Row>
              </div>
              <p>@DISCUNIANDES</p> 
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </div>
  )
}

export default ContactUs