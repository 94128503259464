import React from 'react'
import "./Footer.scss"


function Footer() {
  return (
    <div id='contactUs' >
      <div style={{textAlign: "center", color: "white", textDecoration: "none"}}>
        Universidad de los Andes | Vigilada Mineducación
        <br /> Reconocimiento como Universidad: Decreto 1297 del 30 de mayo de 1964.
        <br /> Reconocimiento personería jurídica: Resolución 28 del 23 de febrero de 1949 Minjusticia
        <br /> Edificio Mario Laserna Cra 1 Este No 19A - 40 Bogotá (Colombia) | Tel: [571] 3394949 Ext: 2860, 2861, 2862 | Fax: [571] 3324325
        <br /> © 2024 - <a className="GENERAL" href="/">Departamento de Ingeniería de Sistemas y Computación</a>
      </div>
    </div>
  )
}

export default Footer