import React, { Fragment } from 'react'
import { Container, Carousel } from 'react-bootstrap'
import "./CampamentoXr.scss"
import bannerCampamento from "../../Assets/banner-campamento.png"
import { Link  } from 'react-router-dom'

function CampamentoXr() {
    return (
        <Fragment>
            <div>
                <img
                    className="d-block w-100"
                    src={bannerCampamento}
                    alt="Banner Campamento XR"
                />
            </div>
            <section id="header">
                <h1 className="text-center">CAMPAMENTO XR DE VIVERO VIRTUAL</h1>
            </section>
            <section>
                <Container>
                    <h2>Motivación</h2>
                    <p>El Campamento de Realidades Extendidas (C-XR) del programa Vivero Virtual (ViVi) es una semana intensiva en Uniandes para formar embajadores de tecnologías XR. Está dirigido a Universidades o instituciones afines que estén interesadas en difundir el uso de éstas tecnologías y en participar activamente en el diseño de nuevas experiencias.</p>
                </Container>
            </section>

            <section>
                <Container>
                    <h2>Características del C-XR</h2>
                    <p>Las características generales del C-XR son las siguientes:</p>
                    <ul>
                        <li>El curso introduce a los participantes a diversas tecnologías XR, sus capacidades y limitaciones, sus usos y sus posibilidades de desarrollo.</li>
                        <li>Es un curso intensivo de 5 días en Uniandes (en horarios de 8am a 6pm).</li>
                        <li>Uniandes cubre los gastos básicos de estadía compartida y alimentación de los participantes.</li>
                        <li>Está abierto a Universidades, con hasta 5 representantes por Universidad, idealmente al menos una mujer por equipo. Adicionalmente, esperamos que la distribución sea de al menos dos profesores interesados en el tema y dos estudiantes que puedan desarrollar actividades en la Universidad, una vez vuelvan. Esperamos que el quinto integrante sea un líder en la Universidad, que supervise las actividades que se realizarán en el <Link to="/laboratorio-xr">Lab XR</Link>,que vislumbre el impacto de las tecnologías en su institución y que sea capaz de gestionar recursos como salones ó espacios para las demostraciones como embajadores en su respectiva institución.</li>
                        <li>Si hay varias Universidades interesadas en una ciudad, es más interesante recibir una propuesta coordinada de todas las instituciones con una participación menos numerosa por universidad (i.e. un estudiante y un profesor de cada Universidad).</li>
                        <li>Dado que estamos buscando el mayor impacto en nuestra población, Uniandes se reserva el derecho de escoger las Universidades participantes en cada uno de los C-XR que tenemos planeados.</li>
                    </ul>
                </Container>
            </section>

            <section>
                <Container>
                    <h2>Compromisos</h2>
                    <p>Los compromisos que adquiere una Universidad al enviar sus representantes al C-XR son los siguientes:</p>
                    <ul>
                        <li>Pedirle a sus representantes el certificado de asistencia a la totalidad del C-XR.</li>
                        <li>Apoyo institucional, en particular para desarrollar actividades de difusión después de asistir al C-XR y la participación en el <Link to="/laboratorio-xr">Lab XR</Link>.</li>
                        <li>Transporte hacia y desde Bogotá para asistir al C-XR para los participantes.</li>
                        <li>Participación futura en el <Link to="/laboratorio-xr">Lab XR</Link>, en donde podrán acceder a un <Link to="/laboratorio-xr">laboratorio de XR</Link> y desarrollar proyectos con éstas tecnologías. </li>
                    </ul>
                </Container>
            </section>

            <section>
                <Container>
                    <h2>Cómo Participar</h2>
                    <ol>
                        <li>Escribir una propuesta, con la información descrita en la sección "Formato de Propuesta".</li>
                        <li>Adjuntar una carta de aval del representante legal de la institución, en la que mencione su respaldo a las personas que van a asistir y a los compromisos adquiridos aquí mencionados.</li>
                        <li>Enviar los dos documentos al correo <a href="mailto:viverovirtual@uniandes.edu.co?subject=Propuesta C-XR-5, Universidad ">viverovirtual@uniandes.edu.co</a>, <strong>desde el 17 de junio al 12 de julio</strong>. El asunto del correo debe ser: "Propuesta C-XR-5, Universidad *Nombre*"</li>
                        <li>Los resultados de la convocatoria (las universidades aceptadas) se enviarán el <strong>13 de Julio</strong></li>
                    </ol>
                    <div className='text-center'>
                        <a className='btn btn-primary btn-lg' href='mailto:viverovirtual@uniandes.edu.co?subject=Propuesta C-XR-5, Universidad ' target='_blank' rel="noreferrer">Envía tus documentos</a>
                    </div>
                </Container>
            </section>

            <section>
                <Container>
                    <h2>Formato de la Propuesta</h2>
                    <p>La propuesta debe tener el siguiente contenido:</p>
                    <ol>
                        <li>Universidad(es) proponente(s).</li>
                        <li>Participantes: nombre, cargo, semestre (para los estudiantes), perfil, breve descripción del interés de cada uno en el programa y compromisos/responsabilidades dentro de la propuesta (qué papel juega cada uno en la propuesta).<br/>Les solicitamos que los estudiantes sean de mitad de carrera, para asegurar su apoyo en los <Link to="/laboratorio-xr">Laboratorios XR</Link> y su continuidad como Embajadores de la Universidad proponente.</li>
                        <li>En caso de que la institución cuente con equipos de XR, cuáles posee? ¿Cómo son usados actualmente?</li>
                        <li>¿Por qué quieren participar en el Campamento XR?</li>
                        <li>Cómo espera impactar su comunidad y su Universidad con el conocimiento adquirido y la futura realización de <Link to="/laboratorio-xr">Laboratorio XR</Link></li>
                        <li>¿Cómo piensa usar los equipos del <Link to="/laboratorio-xr">Laboratorio XR</Link> y por cuánto tiempo?</li>
                    </ol>
                    <p>Dichos usos pueden incluir las siguientes actividades y cada una deberá tener un objetivo de impacto en su comunidad y/o en su Universidad: versiones de CXR en su institución, proyectos de desarrollo, actividades de divulgación, clases cortas a estudiantes de colegios, etc.</p>
                </Container>
            </section>

            <section>
                <Container>
                    <h2>Criterios de selección</h2>
                    <ol>
                        <li>La propuesta evidencia un posible impacto en la comunidad y/o Universidad de los proponentes</li>
                        <li>La propuesta está escrita claramente y demuestra el detalle y compromiso de los proponentes en actividades de divulgación y futura participación en <Link to="/laboratorio-xr">Laboratorio XR</Link></li>
                        <li>Se describe en detalle el equipo participante y la pertinencia de su perfil asociado con la propuesta de participación</li>
                        <li>Los participantes cuentan con aval institucional</li>
                        <li>Los participantes cuentan con disponibilidad de tiempo para participar en el CXR4 durante los 5 días en el horario descrito arriba.</li>
                    </ol>
                    <p>Dichos usos pueden incluir las siguientes actividades y cada una deberá tener un objetivo de impacto en su comunidad y/o en su Universidad: versiones de CXR en su institución, proyectos de desarrollo, actividades de divulgación, clases cortas a estudiantes de colegios, etc.</p>
                </Container>
            </section>

            <section>
                <Container>
                    <h2>El Evento: Campamento XR 5</h2>
                    <ol>
                        <li>Los participantes llegarán el <strong>12 de agosto antes de las 2PM</strong>. Al llegar se les dará un kit de bienvenida y se les ubicará en una habitación compartida.</li>
                        <li>Los participantes deben asistir a las actividades programadas <strong>desde el 12 de agosto al 16 de agosto, de 8am a 6pm</strong>. Se provee la alimentación durante esos días</li>
                        <li>Habrán algunas actividades sociales en otros horarios. Aunque son opcionales, desearíamos su asistencia para crear comunidad.</li>
                        <li>Al final del evento se entregará el certificado de asistencia a las personas que hayan estado en al menos el 90% de las actividades obligatorias.</li>
                        <li>Después de asistir, se espera que la Universidad envíe su propuesta para el Laboratorio XR, la cual será concertada con el equipo organizador del Vivero Virtual</li>
                    </ol>
                </Container>
            </section>

            <section>
                <Container className="pb-4">
                    <Carousel variant="dark">
                        <Carousel.Item>
                            <img className="d-block w-50 m-auto img-fluid" src="https://sistemas.uniandes.edu.co/images/NOTICIAS/2023/09/CXR1-d1.jpg" alt="campamento XR"/>
                            <Carousel.Caption>
                                <h3>CXR-1 - Día 1</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-75 m-auto img-fluid" src="https://sistemas.uniandes.edu.co/images/NOTICIAS/2023/09/CXR1-d2.jpg" alt="campamento XR"/>
                            <Carousel.Caption>
                                <h3>CXR-1 - Día 2</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-50 m-auto img-fluid" src="https://sistemas.uniandes.edu.co/images/NOTICIAS/2023/09/CXR1-d3.jpg" alt="campamento XR"/>
                            <Carousel.Caption>
                                <h3>CXR-1 - Día 3</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-50 m-auto img-fluid" src="https://sistemas.uniandes.edu.co/images/NOTICIAS/2023/09/CXR2-d1.jpg" alt="campamento XR"/>
                            <Carousel.Caption>
                                <h3>CXR-2 - Día 1</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-50 m-auto img-fluid" src="https://sistemas.uniandes.edu.co/images/NOTICIAS/2023/09/CXR2-d2.jpg" alt="campamento XR"/>
                            <Carousel.Caption>
                                <h3>CXR-2 - Día 2</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-50 m-auto img-fluid" src="https://sistemas.uniandes.edu.co/images/NOTICIAS/2023/09/CXR2-d3.jpg" alt="campamento XR"/>
                            <Carousel.Caption>
                                <h3>CXR-2 - Día 3</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-50 m-auto img-fluid" src="https://sistemas.uniandes.edu.co/images/NOTICIAS/2023/09/CXR2-d4.jpg" alt="campamento XR"/>
                            <Carousel.Caption>
                                <h3>CXR-2 - Día 4</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-50 m-auto img-fluid" src="https://sistemas.uniandes.edu.co/images/NOTICIAS/2023/09/CXR2-d5.jpg" alt="campamento XR"/>
                            <Carousel.Caption>
                                <h3>CXR-2 - Día 5</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Container>
            </section>
        </Fragment>
    )
}

export default CampamentoXr