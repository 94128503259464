import React, { Fragment, useState } from 'react'
import { Col, Row, Carousel, Container, Card, Button, Accordion, Stack, Modal } from 'react-bootstrap';
import { Link  } from 'react-router-dom'
// import imgPrincipal from "./Assets/fondoVIVI.jpg";
//import bannerVivero from "../../Assets/colivri.jpg";
import cursosMasivos from "../../Assets/cursos-masivos.png"
import prestamoEquipos from "../../Assets/prestamo-de-equipos.png"
import hallFama from "../../Assets/hall-de-la-fama.png"
import campamentoXr from "../../Assets/campamento-xr.png"
import laboratorioXr from "../../Assets/laboratorio-xr.png"
//import dataFacebook from "../../Assets/LogoViVi_3.jpg";
import ContactUs from '../contactUs/ContactUs'
import announcementsData from '../../local-db/announcements.json'

import "./Home.scss"

function Home() {
    const announcements = announcementsData.news.filter(data => data.visible)
    console.log(announcements)

    const [vivian, setVivian] = useState(false);
    const [pablo, setPablo] = useState(false);
    const [dataCarousel, setDataCarousel] = useState(announcements[0]);
    const [dataCarouselBanner, setDataCarouselBanner] = useState(announcements[0]);

    const handleCloseV = () => setVivian(false);
    const handleShowV = () => setVivian(true);
    const handleCloseP = () => setPablo(false);
    const handleShowP = () => setPablo(true);

    

    const handleSlideChange = (selectedIndex) => {
        setDataCarousel(announcements[selectedIndex])
    }

    const handleSlideChangeBanner = (selectedIndex) => {
        setDataCarouselBanner(announcements[selectedIndex])
    }

    return (
        <Fragment>
            <section className="section-banner">
                <Link to={dataCarouselBanner.url ? dataCarouselBanner.url : '/noticia/'+dataCarouselBanner.id} className="text-decoration-none text-dark">
                    <Carousel onSlide={handleSlideChangeBanner}>
                        {announcements.map(element => (
                            <Carousel.Item interval={5000}>
                                <img
                                    className="d-block w-100"
                                    src={element.image}
                                    alt={element.alt}
                                />
                            </Carousel.Item>)
                        )}
                    </Carousel>
                    <Container className="py-3">
                        <Row className="px-5">
                            <Col className="border-end">
                                <h5 className="fw-bold text-end">{dataCarouselBanner.title}</h5>
                            </Col>
                            <Col>
                                <p className='p-0' style={{fontSize: '0.9rem'}}>{dataCarouselBanner.lead}</p>
                            </Col>
                        </Row>
                    </Container>
                </Link>
            </section>
            <section className="section-primary" id="description">
                <Container>
                    <div className="title">
                        <h2>¿Quienes Somos?</h2>
                        <div className="line-primary"></div>
                    </div>
                    <p>Vivero Virtual es el Programa de Incubadora XR de la Universidad de los Andes que fue beneficiario de la beca Higher Ed XR Innovation Grant de Unity Social Impact y Meta Immersive Learning, gracias a sus propuestas de inclusión, impacto, viabilidad e innovación en la alfabetización desde la académica de este nuevo uso de las tecnologías, para aumentar el acceso a hardware AR/VR, el contenido educativo de alta calidad y otros recursos que ayudarán a los educadores a crear o mejorar programas XR innovadores. </p>
                </Container>
            </section>

            <section className="section-secondary" id="team">
                <Container>
                    <div className="title">
                        <h2>Nuestro equipo</h2>
                        <div className="line-secondary"></div>
                    </div>
                    <Stack gap={3}>
                        <Card>
                            <Row>
                                <Col xs={12} sm md={4} lg={3} xl={2}>
                                    <Card.Img variant="top" src="https://sistemas.uniandes.edu.co/images/Personas/Profesores/vngomez.jpg" />
                                </Col>
                                <Col xs={12} sm md={8} lg={9} xl={10}>
                                    <Card.Body>
                                        <Card.Title>Vivian Gomez,</Card.Title>
                                        <Card.Text>
                                            MSc y BSc en Ingeniería de Sistemas y Computación. Instructora de la Universidad de los Andes.
                                        </Card.Text>
                                        <Button variant="primary" onClick={handleShowV}>Detalles</Button>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                        <Card>
                            <Row>
                                <Col xs={12} sm md={4} lg={3} xl={2}>
                                    <Card.Img variant="top" src="https://profesores.virtual.uniandes.edu.co/pfiguero/wp-content/uploads/sites/35/2019/06/cropped-PabloFigueroa.png" />
                                </Col>
                                <Col xs={12} sm md={8} lg={9} xl={10}>
                                    <Card.Body>
                                        <Card.Title>Pablo Figueroa,</Card.Title>
                                        <Card.Text>
                                            Doctor en Ciencias de la Computación. Profesor Asociado de la Universidad de los Andes.
                                        </Card.Text>
                                        <Button variant="primary" onClick={handleShowP}>Detalles</Button>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Stack>
                </Container>
            </section>

            <section className="section-primary" id="actions-lines">
                <Container>
                    <div className="title">
                        <h2>¿Cuál es nuestro objetivo? </h2>
                        <div className="line-primary"></div>
                    </div>
                    <p>Buscamos brindar aprendizajes en la creación de proyectos en torno a realidad extendida y la creación de contenido para el metaverso, para así, desarrollar la fuerza laboral, fortalecer el conocimiento y aprendizaje a través de la inclusión, el impacto, la viabilidad e innovación en la alfabetización desde la academia de este nuevo uso de las tecnologías.</p>
                </Container>
            </section>

            <section className="section-secondary">
                <Container>
                    <div className="title">
                        <h2>Conoce nuestras 5 acciones para el aprendizaje XR: </h2>
                        <div className="line-secondary"></div>
                    </div>
                    <Row>
                        <Col xl lg={4} md={6} sm={12} className="d-flex align-items-stretch">
                            <Card>
                                <Card.Img variant="top" src={cursosMasivos} />
                                <Card.Body>
                                    <Card.Title>Cursos masivos</Card.Title>
                                    <Card.Text>
                                        Cursos virtuales y abiertos al público para promover el aprendizaje en introducción a XR, Diseño para XR y Programación en XR.
                                    </Card.Text>
                                    <Button variant="primary" disabled>Próximamente</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl lg={4} md={6} sm={12} className="d-flex align-items-stretch">
                            <Card>
                                <Card.Img variant="top" src={prestamoEquipos} />
                                <Card.Body>
                                    <Card.Title>Préstamos de equipos</Card.Title>
                                    <Card.Text>
                                        Los estudiantes exitosos podrán pedir prestados equipos, por un cierto período de tiempo e iniciar sus ideas de proyecto.
                                    </Card.Text>
                                    <Button variant="primary" disabled>Próximamente</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl lg={4} md={6} sm={12} className="d-flex align-items-stretch">
                            <Card>
                                <Card.Img variant="top" src={hallFama} />
                                <Card.Body>
                                    <Card.Title>Hall de la fama</Card.Title>
                                    <Card.Text>
                                        Buscamos reconocer la labor y el trabajo de estudiantes destacados en la iniciativa, con el fin de mejorar la capacidad de contratación de los estudiantes certificados.
                                    </Card.Text>
                                    <Button variant="primary" disabled>Próximamente</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl lg={4} md={6} sm={12} className="d-flex align-items-stretch">
                            <Card>
                                <Card.Img variant="top" src={campamentoXr} />
                                <Card.Body>
                                    <Card.Title>Campamento XR</Card.Title>
                                    <Card.Text>
                                        Programa intensivo de 5 días para grupos de embajadores de Instituciones Educativas, que les dará acceso a una amplia variedad de tecnologías XR.
                                    </Card.Text>
                                    <Card.Link href="/#/campamento-xr" className="btn btn-primary">Participa aquí</Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl lg={4} md={6} sm={12} className="d-flex align-items-stretch">
                            <Card>
                                <Card.Img variant="top" src={laboratorioXr} />
                                <Card.Body>
                                    <Card.Title>Laboratorio XR</Card.Title>
                                    <Card.Text>
                                        Nuestros equipos se trasladan a las universidades. Realizamos el préstamo de equipos Meta Quest para desarrollar proyectos con lo aprendido en XRCamp.
                                    </Card.Text>
                                    <Card.Link href="/#/laboratorio-xr" className="btn btn-primary">Participa aquí</Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-primary" style={{ paddingBottom: "25px" }}>
                <Container className="text-center">
                    <h2>¡Si quieres estar a la vanguardia de la tecnología XR, vivero virtual es para ti!</h2>
                    <h3><a href="https://docs.google.com/forms/d/e/1FAIpQLSdSMK3uXnISTPXoQk2aV4xla8q76FQ1sJrdjJoBxoW0LTLE5w/viewform" target="_blank" rel="noreferrer" className='btn btn-blue'><strong>Únete aquí a esta experiencia</strong></a></h3>
                </Container>
            </section>

            <section className="section-secondary" id="events">
                <Container>
                    <div className="title">
                        <h2>Anuncios</h2>
                        <div className="line-secondary"></div>
                    </div>
                    <Row>
                        <Col xl lg={6} md={12} sm={12} >
                            <Carousel onSlide={handleSlideChange}>
                                {announcements.map(element => (
                                    <Carousel.Item interval={5000}>
                                        <img
                                            className="d-block w-100"
                                            src={element.image}
                                            alt={element.alt}
                                        />
                                    </Carousel.Item>)
                                )}
                            </Carousel>
                        </Col>
                        <Col xl lg={6} md={12} sm={12}>
                            <div className="line-default"></div>
                            <h5>{dataCarousel.title}</h5>
                            <p>{dataCarousel.lead}</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section-primary" id="questions">
                <Container>
                    <div className="title">
                        <h2>Preguntas frecuentes </h2>
                        <div className="line-primary"></div>
                    </div>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>¿Qué es un Embajador en Vivero Virtual?</Accordion.Header>
                            <Accordion.Body>
                                Los Embajadores son participantes que culminan el C-XR y se convierten en líderes en tecnologías XR en sus instituciones, para el desarrollo de proyectos de impacto con el préstamos de equipos del LabXR
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Si fuimos seleccionados para el campamento, ¿La Universidad de los Andes garantiza el transporte de los representantes hacia Bogotá?</Accordion.Header>
                            <Accordion.Body>
                                No. La universidad solo cubre los gastos de estadía y alimentación durante los días del campamento. Por otro lado, dentro de los compromisos de las universidades participantes con sus representantes, es garantizar transporte hacia y desde Bogotá para asistir al C-XR.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Luego de finalizar el campamento. ¿Se hará seguimiento a las diferentes propuestas diseñadas con este tipo de tecnologías?</Accordion.Header>
                            <Accordion.Body>
                                Sí. Desde el Vivero Virtual creemos que es necesario alfabetizar a la academia para el uso de nuevas tecnologías, que aporten al crecimiento de la educación superior en nuestro País e Iberoamérica. Además, capacitaremos embajadores ViVi de instituciones educativas que promuevan el uso de la realidad extendida y participen en nuevas experiencias de innovación, construyendo comunidades de aprendizajes con evidencias científicas.  
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Si como Universidad logramos ser seleccionados en el primer campamento. ¿Se limita la participación en los próximos campamentos XR?</Accordion.Header>
                            <Accordion.Body>
                                No es posible participar en otro campamento. La invitación como embajadores Vivi es seguir implementando estas iniciativas desarrolladas en el C-XR-1 en su institución. Sin embargo, si la institución no fue seleccionada para el primer campamento, puede seguir intentando en las próximas convocatorias que se desarrollen durante los años 2023 y 2024.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>¿Pueden participar universidades de Latinoamérica?</Accordion.Header>
                            <Accordion.Body>
                                Sí. La Universidad de los Andes y el Departamento de Ingeniería de Sistemas pretende impactar en toda la población Iberoamérica, proyectando 5 C-XR planeados para el 2023-2024. Lo cual permitirá alfabetizar a la academia y elaborar proyectos pensados desde el uso de las tecnologías XR en toda la región. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Si la Universidad a la que pertenecemos no cuenta con un conjunto de equipos Lab - XR ¿Es posible participar en el campamento?</Accordion.Header>
                            <Accordion.Body>
                                Si. El objetivo de este campamento es formar embajadores de tecnologías XR, que puedan difundir el uso de estas y participar en el diseño de nuevas experiencias. En caso de no tener este tipo de dispositivos, se puede solicitar el préstamo en el laboratorio XR por un tiempo limitado. Aplica solo para las universidades en Colombia.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>¿Hay algún costo de inscripción a los Campamentos XR?</Accordion.Header>
                            <Accordion.Body>
                                No. Basta con enviar la propuesta en el formato indicado al correo viverovirtual@uniandes.edu.co y asegurarse de cumplir los compromisos descritos en <Link to="/campamento-xr">https://vivero.virtual.uniandes.edu.co/#/campamento-xr</Link>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Container>
            </section>
            <section className="section-secondary" id="contact">
                <Container>
                    <div className="title">
                        <h2>Contáctanos</h2>
                        <div className="line-secondary"></div>
                    </div>
                    <ContactUs></ContactUs>
                </Container>

            </section>

            <Modal show={vivian} onHide={handleCloseV}>
                <Modal.Header closeButton>
                <Modal.Title>Vivian Gomez</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 style={{fontWeight:"bold"}}>Experiencia:</h5>
                    <p>Profesora Instructora de la Universidad de los Andes, Magíster en Ingeniería de Sistemas y Computación. Sus temas de investigación se centran en HCI y el Desarrollo de Realidad Virtual. Fue organizadora del CursoJamVR Uniandes, es coordinadora del Colaboratorio de interacción, visualización, robótica y sistemas autónomos (COLIVRI) de la Universidad de los Andes y co-directora del programa Vivero Virtual</p>
                </Modal.Body>
            </Modal>
            <Modal show={pablo} onHide={handleCloseP}>
                <Modal.Header closeButton>
                <Modal.Title>Pablo Figueroa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 style={{fontWeight:"bold"}}>Experiencia:</h5>
                    <p>Doctor en Ciencias de la Computación. Profesor Asociado de la Universidad de los Andes. Casi 30 años de experiencia en el campo de la Realidad Mixta (Realidad Virtual, Realidad Aumentada y términos relacionados). Ha sido beneficiario de importantes fondos del gobierno colombiano, como el "Proyecto David" (U$ 1M aprox.) para el desarrollo de la industria del videojuego en Colombia, y "Jump Camp" (U$ 400k aprox.) para la enseñanza del desarrollo de videojuegos en toda Colombia. Miembro activo de IEEE VR, CLEI (Centro Latinoamericano de Estudios en Informática), SCo2 (Sociedad Colombiana de Computación) y ACM.</p>
                </Modal.Body>
            </Modal>
        </Fragment>

    )
}

export default Home