import React, { Fragment } from 'react'
/* import { Container, Carousel } from 'react-bootstrap'
import { Link  } from 'react-router-dom' */
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/sea-green';

import "./GuiaCampamentoXr.scss"

import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import eventsData from '../../local-db/events-data.json'

import bannerVivero from "../../Assets/banner-vivero-web.png";
import bannerViveroMobile from "../../Assets/banner-vivero-mobile.png";
import cronogramaVivero from "../../Assets/banner-cronograma-web.png";
import cronogramaViveroMobile from "../../Assets/banner-cronograma-mobile.png";

import pdfMapa from "../../Assets/docs/recorrido-virtual.pdf";


import metaversoVivero from "../../Assets/banner-metaverso-web.png";
import metaversoViveroMobile from "../../Assets/banner-metaverso-mobile.png";

import cafeteria from "../../Assets/cafeteria-alimentacion-vivero.png";
import campus from "../../Assets/acceso-campus-vivero.png";
import tienda from "../../Assets/tienda-uniandes-vivero.png";

import facebook from "../../Assets/facebook-icon.png";
import twitter from "../../Assets/twitter-icon.png";
import instagram from "../../Assets/instagram-icon.png";
import tiktok from "../../Assets/tiktok-icon.png";
import mail from "../../Assets/mail-icon.png";
import linkedin from "../../Assets/linkedin-icon.png";


import { Container } from 'react-bootstrap'


function GuiaCampamentoXr() {
    return (
        <Fragment>

            <section className='w-100'>
                <img
                    className="d-none d-md-block w-100"
                    src={bannerVivero}
                    alt="Banner Campamento XR"
                />
                <img
                    className="d-block d-md-none w-100"
                    src={bannerViveroMobile}
                    alt="Banner Campamento XR"
                />
            </section>

            <section className="container text-center">
                <p className="fs-3 m-auto my-3" style={{color: "#404041"}}>A continuación, encontrarás <span className="fw-bold" style={{color: "#48038C"}}>información importante</span> para tu estancia en Uniandes durante este campamento,	esperamos esta visita a nuestra universidad sea de tu agrado</p>
            </section>

            <section className="fondo360 container-fluid">
                <div className='text-center row pt-4'>
                    <div className="col-12 col-lg-6 p-md-4 pt-5 px-5 d-flex justify-content-md-center text-md-center justify-content-end text-end align-items-center">
                        <div className="w-75">
                            <h2 className="fs-1 fw-bold text-white mt-2">
                                <span style={{color: "#20BADD"}}>¡Hora de conocer</span> la Universidad de los Andes!
                            </h2>
                            <p className="text-white md:text-md lg:text-md mr-2 pl-20 my-2 sm:pl-32 md:my-8 lg:pl-0 lg:mx-32 ">
                                Te invitamos a navegar en nuestro <span style={{color: "#20BADD"}}>recorrido 360°</span> para que puedas reconocer rápidamente	lugares estratégicos en donde se desarrollará el <span className="fw-bold underline underline-offset-4">campamento XR-5</span></p>
                            <div className="map-button">
                                <a href={pdfMapa} target="_blank" rel='noreferrer' className="btn btn-primary text-white p-2 fs-5">Descarga el mapa del campus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                        <div className='w-75 h-75 ratio ratio-16x9'>
                            <iframe title='frame' className="border rounded" src="https://campusinfo.uniandes.edu.co/recorridovirtual/"></iframe>

                        </div>
                    </div>
                </div>
                
            </section>

            <section className="flex justify-center">
                <img
                    className="d-none d-lg-block w-100"
                    src={cronogramaVivero}
                    alt="Cronograma Vivero virtual 2024"
                />
                <img
                    className="d-block d-lg-none w-100"
                    src={cronogramaViveroMobile}
                    alt="Cronograma Vivero virtual 2024 mobile"
                />

            </section>
            
            <section className="w-100 flex justify-center">
                <Container>
                    <FullCalendar
                        plugins={[ timeGridPlugin, listPlugin ]}
                        initialView="timeGridWeek"
                        events={eventsData}
                        themeSystem= "Litera"
                        slotMinTime= "07:00:00"
                        locale= 'es'
                        slotMaxTime= "20:30:00"
                        allDaySlot= {false}
                        validRange = {
                            {
                                "start": "2024-08-12",
                                "end": "2024-08-17"
                            }
                        }
                        headerToolbar= {
                            {
                                left: 'prev,next today',
                                center: 'title',
                                right: 'timeGridWeek,listWeek'
                            }
                        }
                        expandRows = {true}
                        views= {
                            {
                                timeGridWeek: {
                                    type: 'timeGridWeek',
                                    duration: { days: 5 },
                                    buttonText: 'Semana'
                                }
                            }
                        }
                        windowResize = {
                            function(arg) {
                                if (window.innerWidth < 768) {
                                    this.changeView("listWeek");
                                } else {
                                    this.changeView("timeGridWeek");
                                }
                            }
                        }
                    />
                </Container>
            </section>

            <section className="text-center mt-4 pt-4 pt-sm-5 fondo_servicios">
                <div>
                    <h2 className="fs-3 fw-bold text-white w-100 m-auto mt-1"><span style={{color: "#20BADD"}}>Sigue explorando</span> la Universidad de los Andes</h2>
                    <h3 className="fs-5 text-white w-100 m-auto sm:w-2/3 md:mt-1 lg:w-1/3 lg:mt-6">En esta guía se pueden encontrar <span style={{color: "#20BADD"}}>varios servicios útiles</span> durante tu estancia en Uniandes.</h3>
                </div>
            </section>

            <section className="container my-4">
                <Splide 
                    aria-label="Otros links" 
                    options={ {
                        rewind: true,
                        gap: '1rem',
                        perPage: 3,
                        arrows: false,
                        breakpoints: {
                            768: {
                                perPage: 1,
                                gap: '1rem',
                                arrows: true,
                            }
                        },
                    } }
                >
                    <SplideSlide className="other_links">
                        <a href="https://tienda.uniandes.edu.co/es/" target="_blank" rel='noreferrer' className="col-12 col-md-4 text-center">
                            <img
                                className="border rounded border-dark w-100"
                                src={tienda}
                                alt="Tienda Uniandes"
                            />
                        </a>
                    </SplideSlide>
                    <SplideSlide className="other_links">
                        <a href="https://campusinfo.uniandes.edu.co/es/servicios/porterias" target="_blank" rel='noreferrer' className="col-12 col-md-4 text-center">
                            <img
                                className="border rounded border-dark w-100"
                                src={campus}
                                alt="Acceso al campus Uniandes"
                            />
                        </a>
                    </SplideSlide>
                    <SplideSlide className="other_links">
                        <a href="https://servicios.uniandes.edu.co/alimentacion/" target="_blank" rel='noreferrer' className="col-12 col-md-4 text-center">
                            <img
                                className="border rounded border-dark w-100"
                                src={cafeteria}
                                alt="Cafetería y alimentación Vivero"
                            />
                        </a>
                    </SplideSlide>
                </Splide>
            </section>

            <section className='w-100'>
                <img
                    className="d-none d-lg-block w-100"
                    src={metaversoVivero}
                    alt="Metaverso Vivero 2024"
                />
                <img
                    className="d-block d-lg-none w-100"
                    src={metaversoViveroMobile}
                    alt="Metaverso Vivero 2024 mobile"
                />
            </section>

            <section className="container-fluid contactanos py-3">
                <h2 className="text-white fs-2 text-center fw-bold pt-2">Contáctanos</h2>
                <div className='container'>
                    <div className="row mt-4 p-3 border rounded bg-white">
                        <div className="col-12 col-lg-4">
                            <div className="d-flex justify-content-center">
                                <a href="https://www.facebook.com/profile.php?id=100086579619980&mibextid=ZbWKwL" target="_blank" rel='noreferrer'>
                                    <img style={{maxWidth: "50px"}} src={facebook} alt="Facebook Vivero Virtual"/>
                                </a>
                                <a href="https://instagram.com/viverovirtual_uniandes?igshid=Yzg5MTU1MDY=" target="_blank" rel='noreferrer'>
                                    <img style={{maxWidth: "50px"}} src={instagram} alt="Instagram Vivero Virtual"/>
                                </a>
                            </div>
                            <p className="text-center fw-bold fs-6">@VIVEROVIRTUAL_UNIANDES</p>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="d-flex justify-content-center">
                                <a href="mailto:viverovirtual@uniandes.edu.co" target="_blank" rel='noreferrer'>
                                    <img style={{maxWidth: "50px"}} src={mail} alt="Mail Vivero Virtual"/>
                                </a>
                            </div>
                            <p className="text-center fw-bold fs-6">viverovirtual@uniandes.edu.co</p>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="d-flex justify-content-evenly">
                                <a href="https://www.facebook.com/DISCUniandes" target="_blank" rel='noreferrer'>
                                    <img style={{maxWidth: "50px"}} src={facebook} alt="Facebook Vivero Virtual"/>
                                </a>
                                <a href="https://www.instagram.com/discuniandes/" target="_blank" rel='noreferrer'>
                                    <img style={{maxWidth: "50px"}} src={instagram} alt="Instagram Vivero Virtual"/>
                                </a>
                                <a href="https://twitter.com/discuniandes" target="_blank" rel='noreferrer'>
                                    <img style={{maxWidth: "50px"}} src={twitter} alt="twitter Vivero Virtual"/>
                                </a>
                                <a href="https://www.tiktok.com/@discuniandes?_t=8aQEZBS2bxN&_r=1" target="_blank" rel='noreferrer'>
                                    <img style={{maxWidth: "50px"}} src={tiktok} alt="tiktok Vivero Virtual"/>
                                </a>
                                <a href="https://www.linkedin.com/showcase/departamento-de-ingenier%C3%ADa-de-sistemas-y-computaci%C3%B3n/?originalSubdomain=co" target="_blank" rel='noreferrer'>
                                    <img style={{maxWidth: "50px"}} src={linkedin} alt="linkedin Vivero Virtual"/>
                                </a>
                            </div>
                            <p className="text-center fw-bold fs-6">@DISCUNIANDES</p>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default GuiaCampamentoXr