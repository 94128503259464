import React, { Fragment } from 'react'
import { useParams } from "react-router-dom"
import { Container } from 'react-bootstrap'
import ContactUs from '../contactUs/ContactUs'
import "./Noticias.scss"

import announcementsData from '../../local-db/announcements.json'

function Noticias() {
    let { id } = useParams();
    const announcements = announcementsData.news
    let news = announcements.find(data => data.id+'' === id);
    return (
        <Fragment>
            <Container>

                <section id="header" className='mt-4'>
                    <h1 className="text-center">{news.title}</h1>
                </section>

                <div>
                    <img
                        className="d-block w-100"
                        src={news.image}
                        alt="Banner Campamento XR"
                    />
                </div>
                
                <section>
                    <div className='mt-2 py-4' id='lead' >
                        <h5 className='text-center'>{news.lead}</h5>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: news.text}}></div>
                </section>
                <hr></hr>
                <section className='my-4'>
                    <div className="title">
                        <h2>Contáctanos</h2>
                        <div className="line-secondary"></div>
                    </div>
                    <ContactUs></ContactUs>
                </section>
            </Container>
        </Fragment>
    )
}

export default Noticias