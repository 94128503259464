import React, { Fragment } from 'react'
import { Container } from 'react-bootstrap'
import "./LaboratorioXr.scss"
import laboratorioXr1 from "../../Assets/laboratorios-xr-1.png"
import bannerLaboratorios from "../../Assets/banner-laboratorios.png"


function LaboratorioXr() {
    return (
        <Fragment>
            <div>
                <img
                    className="d-block w-100"
                    src={bannerLaboratorios}
                    alt="Banner Laboratorios XR"
                />
            </div>
            <section id="header">
                <h1 className="text-center">LABORATORIO XR DE VIVERO VIRTUAL</h1>
            </section>
            <section>
                <Container>
                    <h2>Lab XR</h2>
                    <p>El Lab XR es un conjunto de equipos que Uniandes presta por un período de tiempo limitado a otra Universidad (o conjunto de Universidades en una ciudad), para la difusión de las tecnologías XR</p>
                </Container>
            </section>

            <section>
                <Container>
                    <h2>Características del Lab-XR</h2>
                    <p>Las características generales del L-XR son las siguientes:</p>
                    <ul>
                        <li>La Universidad que recibe el Lab XR debe tener embajadores certificados por Uniandes, formados en el C-XR, por el período de tiempo del préstamo.</li>
                        <li>Cada Universidad puede proponer su forma de uso de los equipos. Se le dará prioridad a las instituciones que describan un mejor uso de los equipos.</li>
                        <li>La Universidad receptora debe describir cómo se van a mantener los equipos en el mejor estado posible.</li>
                        <li>Inicialmente está dirigido a Universidades colombianas, dada la logística necesaria para préstamo de equipos.</li>
                        <li>Las instituciones que reciban el Lab XR estarán a cargo de los gastos de envío de los equipos desde y hacia Bogotá y de su administración y buen manejo durante el período de préstamo. </li>
                        <li>El envío de los equipos lo asume la Universidad solicitante y dicho envío debe incluir el asegurar los equipos (con la empresa de transporte). </li>
                        <li>La Universidad solicitante se hace responsable de los equipos durante su préstamo para el LabXR. </li>
                        <li>La Universidad solicitante podrá pedir en préstamo el laboratorio de cascos por un tiempo no mayor a 1 mes.</li>
                    </ul>
                    <p>Si la Universidad receptora ya tiene equipos de XR, va a ser interesante ver cómo el Lab XR complementa la capacidad instalada y potencia nuevos usos.</p>
                </Container>
            </section>

            <section>
                <Container className="pb-4">
                    <img
                        className="d-block w-100"
                        src={laboratorioXr1}
                        alt="laboratorio XR"
                    />
                </Container>
            </section>

        </Fragment>
    )
}

export default LaboratorioXr